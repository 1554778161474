@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&display=swap');

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
	font-family: "Roboto", "Arial";
	font-weight: 400;
	background-color: #efefef;
	animation: fade-in 1.5s ease;
	color: #2f2f2f;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: inherit;
}

*:focus {
	outline: none;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  display: block;
	color: #8e8e8e
}

textarea {
  resize: none;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

div[role="tooltip"] {
	top: 0;
	z-index: 101;
}

.editable {
	color: #ff5722;
}
